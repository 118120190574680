// Как использовать попап
/**
const handleShowPopup = () => {
  const id = showPopup({
    title: 'Привет!',
    text: 'Это пример всплывающего сообщения.',
    btnText: 'Закрыть',
    onClick: () => console.log('Кнопка была нажата'),
    callback: () => console.log('Попап закрыт автоматически или по кнопке'),
  });
  setPopupId(id); // Сохраняем id для последующего обновления
};

const handleUpdatePopup = () => {
  if (popupId) {
    updatePopup(popupId, {
      text: 'Обновленный текст попапа!',
      btnText: 'Обновить еще раз',
    });
  }
};
*/
import React, { ReactNode, useCallback, useState } from 'react';

import Popup, { IPopupProps } from '@component/popup/popup';
import { PopupPortal } from '@component/popup-portal/popup-portal';

interface IPopupContext {
  showPopup: (popupProps: IPopupProps) => void;
  hidePopup: (popupId?: string) => void;
  updatePopup: (popupId: string, updatedProps: Partial<IPopupProps>) => void;
}

export const PopupContext = React.createContext<IPopupContext | undefined>(undefined);

interface IPopupProviderProps {
  children: ReactNode;
}

interface IPopupWithId extends IPopupProps {
  id: string;
}

export const PopupProvider: React.FC<IPopupProviderProps> = ({ children }) => {
  const [popups, setPopups] = useState<IPopupWithId[]>([]);

  const showPopup = useCallback((popupProps: IPopupProps) => {
    const newPopup: IPopupWithId = {
      id: `popup-${Date.now()}`,
      ...popupProps,
    };
    setPopups((prevPopups) => [...prevPopups, newPopup]);
    return newPopup.id; // Возвращаем id попапа
  }, []);

  const hidePopup = useCallback((popupId?: string) => {
    if (popupId) {
      setPopups((prevPopups) => prevPopups.filter((popup) => popup.id !== popupId));
    } else {
      // Если не указан конкретный popupId, закрываем последний попап
      setPopups((prevPopups) => prevPopups.slice(0, -1));
    }
  }, []);

  const updatePopup = useCallback(
    (popupId: string, updatedProps: Partial<IPopupProps>) =>
      setPopups((prevPopups) =>
        prevPopups.map((popup) => (popup.id === popupId ? { ...popup, ...updatedProps } : popup)),
      ),
    [],
  );

  return (
    <PopupContext.Provider value={{ showPopup, hidePopup, updatePopup }}>
      <>
        {children}
        {popups.map((popup) => {
          const defaultProps: Partial<IPopupProps> = {
            showTime: 4,
            align: 'center',
            positionVertical: 'bottom',
            width: 'fill',
          };

          return (
            <PopupPortal key={popup.id}>
              <Popup
                {...defaultProps}
                {...popup}
                callback={() => {
                  popup.callback?.();
                  hidePopup(popup.id);
                }}
              />
            </PopupPortal>
          );
        })}
      </>
    </PopupContext.Provider>
  );
};
